import { createContext, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeSetting } from 'src/modules/user/domain/model/user';
import { ROOT_PATHNAME } from 'src/router/pageInfo';
import useLayout, { CreateStatus, DataEmptyStatus } from 'src/shared/hooks/useLayout';
import { HOME_SETTING_KEY, WORKSPACE_ID_KEY, getLocalStorage, setLocalStorage } from '../auth0/localStorage';

export interface WorkspaceContextStore {
  createStatus: CreateStatus;
  dataEmptyStatus: DataEmptyStatus;
  homeSetting: HomeSetting | null;
  isLoading: boolean;
  workSpaceId: string | null;
}

export const WorkspaceContextDefaultState: WorkspaceContextStore = {
  createStatus: {
    cameraTask: false,
  },
  dataEmptyStatus: {
    hasCamera: false,
    hasCameraEventLog: false,
    hasCameraTask: false,
    hasComputeTarget: false,
    hasDataSet: false,
    hasGroupTask: false,
    hasModel: false,
    hasTrafficAsset: false,
    hasVideo: false,
    hasVideoEventLog: false,
    hasVideoTask: false,
  },
  homeSetting: 'observ',
  isLoading: false,
  workSpaceId: null,
};

const WorkspaceContext = createContext<WorkspaceContextStore>(WorkspaceContextDefaultState);

export default WorkspaceContext;

export function useWorkspaceContext(): WorkspaceContextStore {
  const { createStatus, dataEmptyStatus, homeSetting, isLoading, workSpaceId } = useContext(WorkspaceContext);

  return {
    createStatus,
    dataEmptyStatus,
    homeSetting,
    isLoading,
    workSpaceId,
  };
}

export function useWorkspaceContextValues(): WorkspaceContextStore {
  const navigate = useNavigate();
  const { createStatus, dataEmptyStatus, homeSetting, isLoading } = useLayout();
  const workSpaceId = getLocalStorage(WORKSPACE_ID_KEY);

  useEffect(() => {
    const homeSettingByStorage = getLocalStorage(HOME_SETTING_KEY);

    if (!homeSettingByStorage && !isLoading && homeSetting) {
      setLocalStorage(HOME_SETTING_KEY, homeSetting);

      const DEFAULT_ENTRY_PATH = homeSetting === 'observ' ? ROOT_PATHNAME.getStarted : ROOT_PATHNAME.home;

      navigate(DEFAULT_ENTRY_PATH, {
        replace: true,
      });
    }
  }, [homeSetting, navigate, isLoading]);

  const contextValues = useMemo(
    () => ({
      createStatus,
      dataEmptyStatus,
      homeSetting,
      isLoading,
      workSpaceId,
    }),
    [dataEmptyStatus, isLoading, homeSetting, workSpaceId, createStatus],
  );

  return contextValues;
}
