import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ImgLogo } from 'src/images';
import { UserPermissionsKey } from 'src/modules/user/domain/model/user';
import { useUserContext } from 'src/modules/user/services/store/UserContext';
import { RootPathname, PageName } from 'src/router/model';
import { useProtectedPages } from 'src/router/pageInfo';
import usePage from 'src/router/utils';
import useTranslation from 'src/shared/hooks/useTranslation';
import generalMessages from 'src/shared/i18n/generalMessages';
import ALL_SCENARIO_ID from 'src/shared/libs/constants/allScenarioId';
import { usePageContext } from 'src/shared/services/store/PageContext';
import { useWorkspaceContext } from 'src/shared/services/store/WorkspaceContext';
import { LinkComponentProps, Sidebar } from 'visionai-baseui';

const NAV_GROUP: RootPathname[][] = [
  ['/', '/get-started'],
  ['/task', '/history', '/analytics', '/resource', '/detection', '/vlmplayground'],
  ['/system-management', '/user-management'],
];
const USER_PATH = '/user';

function LinkComponent({ children, className, name, path }: LinkComponentProps): JSX.Element {
  const {
    setDetectionPage,
    setDetectionViewType,
    setScenarioId,
    setSortConfig,
    setTaskPage,
    setTaskType,
    setViewType,
  } = usePageContext();

  const onLinkClick = useCallback(() => {
    if ((name as PageName) === 'Task') {
      setTaskPage(1);
      setTaskType('camera');
      setViewType('card');
      setScenarioId(ALL_SCENARIO_ID);
      setSortConfig({
        direction: 'desc',
        key: 'id',
      });
    }

    if ((name as PageName) === 'Detection') {
      setDetectionPage(1);
      setTaskPage(1);
      setDetectionViewType('scenario');
    }
  }, [
    name,
    setTaskPage,
    setDetectionPage,
    setDetectionViewType,
    setTaskType,
    setViewType,
    setScenarioId,
    setSortConfig,
  ]);

  return (
    <Link className={className} title={name} to={path} onClick={onLinkClick}>
      {children}
    </Link>
  );
}

function Navigation(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { permission } = useUserContext();
  const { homeSetting } = useWorkspaceContext();
  const protectedPages = useProtectedPages();
  const { pageIconMap, pageNameMap, pageSystemMap } = usePage();
  const { format } = useTranslation();

  const currentLocation = useMemo(() => `/${location.pathname.split('/')[1]}`, [location]);
  const {
    userProfile: { avatar, firstName, lastName },
  } = useUserContext();

  const navigation = useMemo(() => {
    if (!homeSetting) return [];

    return NAV_GROUP.map(pathGroup =>
      pathGroup
        .filter(path => pageSystemMap[path].indexOf(homeSetting) > -1)
        .map(path => ({
          icon: pageIconMap[path],
          isActive: path === currentLocation,
          name: pageNameMap[path],
          path,
        }))
        .filter(
          ({ path }) =>
            permission[
              protectedPages.find(({ path: protectPath }) => protectPath === path)
                ?.requiredPermissions as UserPermissionsKey
            ]?.canViewPage ?? true,
        ),
    ).filter(pathGroup => pathGroup.length);
  }, [currentLocation, homeSetting, pageIconMap, pageNameMap, pageSystemMap, permission, protectedPages]);

  return (
    <div className="w-[4rem]">
      <Sidebar
        header={
          <div className="flex items-center text-xl font-bold leading-7">
            <img alt="" className="mr-2.5 h-6 w-6" src={ImgLogo} />
            Observ
          </div>
        }
        headerHeightClassName="h-[5.75rem]"
        imageCrossOrigin="anonymous"
        linkComponent={LinkComponent}
        navigation={navigation}
        user={{
          avatar,
          fullName: `${firstName} ${lastName}`,
          onClick: () => navigate(USER_PATH),
          title: format(generalMessages, 'viewProfile'),
        }}
      />
    </div>
  );
}

export default Navigation;
