import { Profile, User, Layout } from './model/user';

export const USER_INIT_DATA: User = {
  avatar: '',
  email: '',
  firstName: '',
  hasUnread: false,
  homeSetting: 'observ',
  id: -1,
  isActive: false,
  language: 'en',
  lastName: '',
  organization: { id: -1, name: '' },
  phone: '',
  role: 1,
  timezone: 'Asia/Taipei',
};

export const PROFILE_INIT_DATA: Profile = {
  ...USER_INIT_DATA,
  workspace: {
    name: '',
    planType: 'PAYG',
    providerType: 'linker',
    tenantId: '',
    workspaceId: '',
  },
};

export const ORGANIZATION_INIT_DATA = {
  description: '',
  id: -1,
  name: '',
  parent: null,
  position: 0,
};

export const LAYOUT_INIT_DATA: Layout = {
  canCreate: {
    cameraTask: false,
  },
  homeSetting: null,
  isCreated: {
    hasCamera: false,
    hasCameraEventLog: false,
    hasCameraTask: false,
    hasComputeTarget: false,
    hasDataSet: false,
    hasGroupTask: false,
    hasModel: false,
    hasTrafficAsset: false,
    hasVideo: false,
    hasVideoEventLog: false,
    hasVideoTask: false,
  },
};
