import { JsonObject } from 'src/shared/libs/model/jsonObject';
import { getAccessToken } from '../auth0/auth0Client';
import { WORKSPACE_ID_KEY, getLocalStorage } from '../auth0/localStorage';
import checkApiError from './checkApiError';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const API_URL = process.env.REACT_APP_API_URL!;

export interface RequestParams {
  [key: string]: string | number | boolean | number[] | string[];
}

export type RequestBody =
  | {
      [key: string]: JsonObject;
    }
  | JsonObject;

interface FormDataBody {
  [key: string]: JsonObject | File | File[];
}

const getHeaders = async (
  isApplicationJsonType = true,
  withCredentials = true,
  customHeaders = {},
): Promise<globalThis.Headers> => {
  const auth0AccessToken = await getAccessToken();
  const workSpaceId = getLocalStorage(WORKSPACE_ID_KEY);

  return new Headers({
    ...(isApplicationJsonType && {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    ...(withCredentials && {
      Authorization: `Bearer ${auth0AccessToken}`,
    }),
    ...(workSpaceId && {
      'X-Request-Service-Id': workSpaceId,
    }),
    ...(customHeaders && {
      ...customHeaders,
    }),
  });
};

function getUrl(path: string, params: RequestParams = {}, host?: null | string): string {
  return Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null)
    .reduce((paramsString, [key, value]) => {
      return `${paramsString}${key}=${value}&`;
    }, `${host ?? API_URL}${path}?`)
    .slice(0, -1);
}

function getFormData(body: FormDataBody): FormData {
  const formData = new FormData();

  Object.entries(body).forEach(([key, value]) => {
    if (Array.isArray(value) && value[0] instanceof File) {
      value.forEach(item => {
        formData.append(key, item as File);
      });

      return;
    }

    if (value instanceof File) {
      formData.append(key, value);

      return;
    }

    if (typeof value === 'object') {
      formData.append(key, JSON.stringify(value));

      return;
    }

    if (value !== null) {
      formData.append(key, `${value}`);
    }
  });

  return formData;
}

export async function apiGet(path: string, params?: RequestParams): Promise<void>;
export async function apiGet<T>(path: string, params?: RequestParams): Promise<T>;
export async function apiGet<T>(path: string, params?: RequestParams): Promise<T | void> {
  const url = getUrl(path, params);
  const response = await fetch(url, {
    headers: await getHeaders(),
    method: 'GET',
  });

  await checkApiError(response, { method: 'GET', url });

  const contentType = response.headers.get('content-type');

  if (contentType?.includes('application/json')) return response.json();
}

export async function apiGetFile(path: string): Promise<Blob> {
  const response = await fetch(path, {
    headers: await getHeaders(true, true),
    method: 'get',
  });

  return response.blob();
}

export async function apiPost(
  path: string,
  body?: RequestBody,
  params?: RequestParams,
  withCredentials?: boolean,
  host?: null | string,
): Promise<void>;
export async function apiPost<T extends JsonObject = JsonObject>(
  path: string,
  body?: RequestBody,
  params?: RequestParams,
  withCredentials?: boolean,
  host?: null | string,
): Promise<T>;
export async function apiPost<T extends JsonObject = JsonObject>(
  path: string,
  body?: RequestBody,
  params?: RequestParams,
  withCredentials?: boolean,
  host?: null | string,
): Promise<T | void> {
  const url = getUrl(path, params, host);

  const response = await fetch(url, {
    body: JSON.stringify(body),
    headers: await getHeaders(true, withCredentials),
    method: 'POST',
  });

  await checkApiError(response, { body, method: 'POST', url });

  const contentType = response.headers.get('content-type');

  if (contentType?.includes('application/json')) return response.json();
}

export async function apiPut(path: string, body?: RequestBody, params?: RequestParams): Promise<void>;
export async function apiPut<T extends JsonObject = JsonObject>(
  path: string,
  body?: RequestBody,
  params?: RequestParams,
): Promise<T>;
export async function apiPut<T extends JsonObject = JsonObject>(
  path: string,
  body?: RequestBody,
  params?: RequestParams,
): Promise<T | void> {
  const url = getUrl(path, params);
  const response = await fetch(url, {
    body: JSON.stringify(body),
    headers: await getHeaders(),
    method: 'PUT',
  });

  await checkApiError(response, { body, method: 'PUT', url });

  const contentType = response.headers.get('content-type');

  if (contentType?.includes('application/json')) return response.json();
}

export async function apiPatch(path: string, body?: RequestBody, params?: RequestParams): Promise<void>;
export async function apiPatch<T extends JsonObject = JsonObject>(
  path: string,
  body?: RequestBody,
  params?: RequestParams,
): Promise<T>;
export async function apiPatch<T extends JsonObject = JsonObject>(
  path: string,
  body?: RequestBody,
  params?: RequestParams,
): Promise<T | void> {
  const url = getUrl(path, params);
  const response = await fetch(url, {
    body: JSON.stringify(body),
    headers: await getHeaders(),
    method: 'PATCH',
  });

  await checkApiError(response, { body, method: 'PATCH', url });

  const contentType = response.headers.get('content-type');

  if (contentType?.includes('application/json')) return response.json();
}

export async function apiDelete(path: string, params?: RequestParams): Promise<void> {
  const url = getUrl(path, params);
  const response = await fetch(url, {
    headers: await getHeaders(),
    method: 'DELETE',
  });

  await checkApiError(response, { method: 'DELETE', url });
}

export async function apiPostFormData(path: string, body: FormDataBody): Promise<void>;
export async function apiPostFormData<T = unknown>(path: string, body: FormDataBody): Promise<T>;
export async function apiPostFormData<T = unknown>(path: string, body: FormDataBody): Promise<T | void> {
  const url = getUrl(path);
  const formData = getFormData(body);

  const response = await fetch(url, {
    body: formData,
    headers: await getHeaders(false),
    method: 'POST',
  });

  await checkApiError(response, { body: formData, method: 'POST', url });

  const contentType = response.headers.get('content-type');

  if (contentType?.includes('application/json')) return response.json();
}

export async function apiPostDownload(
  path: string,
  body?: RequestBody,
  params?: RequestParams,
  headers?: HeadersInit,
): Promise<Blob> {
  const url = getUrl(path, params);

  const response = await fetch(url, {
    body: JSON.stringify(body),
    headers: await getHeaders(true, true, headers),
    method: 'POST',
  });

  await checkApiError(response, { body, method: 'POST', url });

  return response.blob();
}

export async function apiPatchFormData(path: string, body: FormDataBody): Promise<void>;
export async function apiPatchFormData<T = unknown>(path: string, body: FormDataBody): Promise<T>;
export async function apiPatchFormData<T = unknown>(path: string, body: FormDataBody): Promise<T | void> {
  const url = getUrl(path);
  const formData = getFormData(body);

  const response = await fetch(url, {
    body: formData,
    headers: await getHeaders(false),
    method: 'PATCH',
  });

  await checkApiError(response, { body: formData, method: 'PATCH', url });

  const contentType = response.headers.get('content-type');

  if (contentType?.includes('application/json')) return response.json();
}
