import { LazyExoticComponent, ComponentType } from 'react';
import { HomeSetting, UserPermissionsKey } from 'src/modules/user/domain/model/user';
import { useUserContext } from 'src/modules/user/services/store/UserContext';
import useTranslation from 'src/shared/hooks/useTranslation';
import generalMessages from 'src/shared/i18n/generalMessages';
import lazyWithRetry from './lazyLoad';
import { PublicPathname, ProtectedPathname, RootPathname, RootPathKey } from './model';
import MODULE_ICONS from './moduleIcons';

export const publicPages: {
  element: LazyExoticComponent<ComponentType>;
  path: PublicPathname;
}[] = [
  {
    element: lazyWithRetry(() => import('src/shared/components/NotFoundPage/NotFoundPage'), 'pageNotFound'),
    path: '/page-not-found',
  },
  {
    element: lazyWithRetry(() => import('src/shared/components/AccessDenied/AccessDeniedForAuth0'), 'accessDeniedAuth'),
    path: '/authAccessDenied',
  },
  {
    element: lazyWithRetry(() => import('src/shared/components/AccessDenied/AccessDenied'), 'accessDenied'),
    path: '/accessDenied',
  },
  {
    element: lazyWithRetry(() => import('src/layout/Check'), 'checkWorkSpaceId'),
    path: '/check/:workspaceId',
  },
];

interface PathInfo {
  component: LazyExoticComponent<ComponentType>;
  icon: JSX.Element;
  name: string;
  path: ProtectedPathname;
  requiredPermissions: UserPermissionsKey | '';
  system: HomeSetting[];
}

export const ROOT_PATHNAME: Record<RootPathKey, RootPathname> = {
  analytics: '/analytics',
  detection: '/detection',
  getStarted: '/get-started',
  history: '/history',
  home: '/',
  notification: '/notification',
  resource: '/resource',
  systemManagement: '/system-management',
  task: '/task',
  user: '/user',
  userManagement: '/user-management',
  vlmPlayground: '/vlmplayground',
};

export const useProtectedPages = (): PathInfo[] => {
  const { format } = useTranslation();
  const {
    userProfile: { homeSetting },
  } = useUserContext();

  return [
    {
      component: lazyWithRetry(() => import('src/modules/home/components/HomePage/HomePage'), 'home'),
      icon: MODULE_ICONS.home,
      name: format(generalMessages, 'home'),
      path: ROOT_PATHNAME.home,
      requiredPermissions: '',
      system: ['observ', 'vehicle'],
    },
    {
      component: lazyWithRetry(
        () => import('src/modules/getStarted/components/GetStartedPage/GetStartedPage'),
        'getStarted',
      ),
      icon: MODULE_ICONS.getStarted,
      name: format(generalMessages, 'getStarted'),
      path: ROOT_PATHNAME.getStarted,
      requiredPermissions: '',
      system: ['observ'],
    },
    {
      component: lazyWithRetry(() => import('src/modules/task/components/TaskPage/TaskPage'), 'task'),
      icon: MODULE_ICONS.task,
      name: format(generalMessages, 'task'),
      path: ROOT_PATHNAME.task,
      requiredPermissions: homeSetting === 'observ' ? 'taskDashboard' : 'vehicleTask',
      system: ['observ', 'vehicle'],
    },
    {
      component: lazyWithRetry(() => import('src/modules/task/components/TaskDetailPage/TaskDetailPage'), 'taskDetail'),
      icon: MODULE_ICONS.task,
      name: format(generalMessages, 'taskDetail'),
      path: `${ROOT_PATHNAME.task}/:taskId`,
      requiredPermissions: 'taskDashboard',
      system: ['observ'],
    },
    {
      component: lazyWithRetry(
        () => import('src/modules/task/components/TaskDetailPage/GroupTaskDetailPage'),
        'groupTaskDetail',
      ),
      icon: MODULE_ICONS.task,
      name: format(generalMessages, 'taskDetail'),
      path: `${ROOT_PATHNAME.task}/group/:taskId`,
      requiredPermissions: 'taskDashboard',
      system: ['observ'],
    },
    {
      component: lazyWithRetry(
        () => import('src/modules/task/components/TaskDetailPage/MTMCTaskDetailPage'),
        'MTMCTaskDetail',
      ),
      icon: MODULE_ICONS.task,
      name: format(generalMessages, 'taskDetail'),
      path: `${ROOT_PATHNAME.task}/mtmc/:taskId`,
      requiredPermissions: 'taskDashboard',
      system: ['observ'],
    },
    {
      component: lazyWithRetry(() => import('src/modules/eventLog/component/HistoryPage/HistoryPage'), 'history'),
      icon: MODULE_ICONS.history,
      name: format(generalMessages, 'history'),
      path: ROOT_PATHNAME.history,
      requiredPermissions: homeSetting === 'observ' ? 'history' : 'vehicleHistory',
      system: ['observ', 'vehicle'],
    },
    {
      component: lazyWithRetry(
        () => import('src/modules/analytics/components/AnalyticsPage/AnalyticsPage'),
        'analytics',
      ),
      icon: MODULE_ICONS.analytics,
      name: format(generalMessages, 'analytics'),
      path: ROOT_PATHNAME.analytics,
      requiredPermissions: homeSetting === 'observ' ? 'analytics' : 'vehicleAnalytics',
      system: ['observ', 'vehicle'],
    },
    {
      component: lazyWithRetry(() => import('src/modules/resource/components/Resource/Resource'), 'resource'),
      icon: MODULE_ICONS.resource,
      name: format(generalMessages, 'resource'),
      path: ROOT_PATHNAME.resource,
      requiredPermissions: 'resource',
      system: ['observ'],
    },
    {
      component: lazyWithRetry(
        () => import('src/modules/detection/components/DetectionPage/DetectionPage'),
        'detection',
      ),
      icon: MODULE_ICONS.detection,
      name: format(generalMessages, 'detection'),
      path: ROOT_PATHNAME.detection,
      requiredPermissions: homeSetting === 'observ' ? 'detection' : 'vehicleDetection',
      system: ['observ', 'vehicle'],
    },
    {
      component: lazyWithRetry(
        () => import('src/modules/systemManagement/components/SystemManagementPage/SystemManagementPage'),
        'systemManagement',
      ),
      icon: MODULE_ICONS.systemManagement,
      name: format(generalMessages, 'systemManagement'),
      path: ROOT_PATHNAME.systemManagement,
      requiredPermissions: homeSetting === 'observ' ? 'systemManagement' : 'vehicleSystemManagement',
      system: ['observ', 'vehicle'],
    },
    {
      component: lazyWithRetry(
        () => import('src/modules/user/components/UserManagement/UserManagement'),
        'userManagement',
      ),
      icon: MODULE_ICONS.userManagement,
      name: format(generalMessages, 'userManagement'),
      path: ROOT_PATHNAME.userManagement,
      requiredPermissions: 'userManagement',
      system: ['observ', 'vehicle'],
    },
    {
      component: lazyWithRetry(
        () => import('src/modules/user/components/UserProfilePage/UserProfilePage'),
        'userProfile',
      ),
      icon: MODULE_ICONS.user,
      name: format(generalMessages, 'userProfile'),
      path: ROOT_PATHNAME.user,
      requiredPermissions: '',
      system: ['observ', 'vehicle'],
    },
    {
      component: lazyWithRetry(
        () => import('src/modules/notification/components/NotificationPage/NotificationPage'),
        'notifications',
      ),
      icon: MODULE_ICONS.notification,
      name: format(generalMessages, 'notifications'),
      path: ROOT_PATHNAME.notification,
      requiredPermissions: '',
      system: ['observ'],
    },
    {
      component: lazyWithRetry(() => import('src/modules/event/components/EventEditor'), 'eventEditor'),
      icon: MODULE_ICONS.detection,
      name: format(generalMessages, 'eventEditor'),
      path: `${ROOT_PATHNAME.detection}/event/:eventId`,
      requiredPermissions: 'detection',
      system: ['observ'],
    },
    {
      component: lazyWithRetry(() => import('src/modules/event/components/EventEditor'), 'addEvent'),
      icon: MODULE_ICONS.detection,
      name: format(generalMessages, 'eventEditor'),
      path: `${ROOT_PATHNAME.detection}/event/add`,
      requiredPermissions: 'detection',
      system: ['observ'],
    },
    {
      component: lazyWithRetry(() => import('src/modules/vlmPlayground/components/VLMPage'), 'vlmPlayground'),
      icon: MODULE_ICONS.vlmPlayground,
      name: `${format(generalMessages, 'vlmPlayground')} (Beta)`,
      path: ROOT_PATHNAME.vlmPlayground,
      requiredPermissions: 'vlm',
      system: ['observ'],
    },
  ];
};
