import { Auth0Provider } from '@auth0/auth0-react';
import NotificationContext, {
  useNotificationContextValue,
} from 'src/modules/notification/services/store/NotificationContext';
import { UserContext, useUserContextValues } from 'src/modules/user/services/store/UserContext';
import isOffline from 'src/shared/libs/isOffline';
import { ReactProps } from 'src/shared/libs/model/react';
import RequireAuth from 'src/shared/services/auth0/RequireAuth';
import KeycloakInit from 'src/shared/services/keycloak/keycloakInit';
import KeycloakRequireAuth from 'src/shared/services/keycloak/RequireAuth';
import PageContext, { usePageContextValues } from 'src/shared/services/store/PageContext';
import WorkspaceContext, { useWorkspaceContextValues } from 'src/shared/services/store/WorkspaceContext';

function GlobalContexts({ children }: ReactProps): JSX.Element {
  const pageContextValues = usePageContextValues();
  const notificationContextValue = useNotificationContextValue();

  return (
    <NotificationContext.Provider value={notificationContextValue}>
      <PageContext.Provider value={pageContextValues}>{children}</PageContext.Provider>
    </NotificationContext.Provider>
  );
}

function UserContexts({ children }: ReactProps): JSX.Element {
  const userContextValues = useUserContextValues();
  const workspaceContextValue = useWorkspaceContextValues();

  return (
    <UserContext.Provider value={userContextValues}>
      <WorkspaceContext.Provider value={workspaceContextValue}>
        <GlobalContexts>{children}</GlobalContexts>
      </WorkspaceContext.Provider>
    </UserContext.Provider>
  );
}

export default function GlobalContextsProvider({ children }: ReactProps): JSX.Element {
  if (isOffline()) {
    return (
      <KeycloakInit>
        <KeycloakRequireAuth>
          <UserContexts>{children}</UserContexts>
        </KeycloakRequireAuth>
      </KeycloakInit>
    );
  }

  return (
    <Auth0Provider
      authorizationParams={{
        audience: process.env.REACT_APP_LINKER_AUDIENCE,
        redirect_uri: window.location.origin + process.env.PUBLIC_URL,
      }}
      cacheLocation="localstorage"
      clientId={process.env.REACT_APP_LINKER_CLIENT_ID as string}
      cookieDomain={process.env.REACT_APP_LINKER_COOKIE_DOMAIN}
      domain={process.env.REACT_APP_LINKER_DOMAIN as string}
    >
      <RequireAuth>
        <UserContexts>{children}</UserContexts>
      </RequireAuth>
    </Auth0Provider>
  );
}
