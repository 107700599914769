import linkerKeycloak from './keycloak';
import useKeycloakQuery from './keycloakQuery';

export default function RequireKeycloakAuth({ children }: { children: JSX.Element }): JSX.Element | null {
  const { data: isAuthenticated, isLoading } = useKeycloakQuery();

  if (isLoading) return null;

  if (!isAuthenticated) {
    linkerKeycloak?.login({ redirectUri: window.location.origin });

    return null;
  }

  return children;
}
