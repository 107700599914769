import { useEffect, type ReactNode } from 'react';
import linkerKeycloak from './keycloak';
import useKeycloakQuery from './keycloakQuery';

export default function KeycloakInit({ children }: { children: ReactNode }): JSX.Element {
  const { data: authenticated, isLoading } = useKeycloakQuery();

  useEffect(() => {
    if (!authenticated && !isLoading) linkerKeycloak?.login({ redirectUri: window.location.origin });
  }, [authenticated, isLoading]);

  return authenticated && children;
}
