/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import linkerKeycloak from './keycloak';

const KEYCLOAK_QUERY_KEY = 'keycloak';

export default function useKeycloakQuery(): UseQueryResult<any> {
  return useQuery(
    [KEYCLOAK_QUERY_KEY],
    () => linkerKeycloak?.init({ checkLoginIframe: false, onLoad: 'login-required' }),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: Infinity,
    },
  );
}
