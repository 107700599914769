import { UserPermission } from './model/user';

const PERMISSION_INIT_DATA: UserPermission[] = [
  {
    id: 1,
    name: 'Viewer',
    permission: {
      analytics: {
        canUseFilter: true,
        canViewPage: true,
      },
      detection: {
        canEditEvent: false,
        canEditScenario: false,
        canViewPage: true,
      },
      history: {
        canUseFilter: true,
        canViewPage: true,
      },
      resource: {
        canAddCamera: true,
        canAddLocation: true,
        canAddVideo: false,
        canDeleteCamera: true,
        canDeleteLocation: true,
        canDeleteVideo: false,
        canEditCamera: true,
        canEditLocation: true,
        canEditVideo: false,
        canViewCamera: true,
        canViewLocation: true,
        canViewPage: true,
        canViewVideo: false,
      },
      systemManagement: {
        canViewPage: true,
        editModelDeployment: false,
        editStorageDay: false,
      },
      taskDashboard: {
        canAddGroupTask: false,
        canAddMultiCameraGroupTask: false,
        canAddTask: false,
        canAddVideoTask: false,
        canViewPage: true,
        canViewTask: true,
        canViewVideoTask: false,
      },
      taskDetail: {
        canDeleteTask: false,
        canPauseTask: false,
        canTerminateTask: false,
        canViewPage: true,
        canViewSetting: false,
        eventLog: {
          canEditNote: false,
          canEditStatus: false,
        },
        setting: {
          detection: {
            canEditAiThreshold: false,
            canEditObjectSize: false,
            canViewAiThreshold: false,
            canViewObjectSize: false,
          },
          event: {
            canEditTrigger: false,
            canViewTrigger: false,
          },
          task: {
            canCaptureSnapshot: false,
            canEditCamera: false,
            canEditImageCollection: false,
            canEditLocation: false,
            canEditNotification: false,
            canEditROI: false,
            canEditTaskGroup: false,
            canEditTaskName: false,
            canEditVideoRecording: false,
            canEditZone: false,
            canRecordVideo: false,
            canUseAIConfidence: false,
            canViewImageCollection: false,
          },
        },
      },
      userManagement: {
        canAddOrganization: false,
        canAddUser: false,
        canChangeOrganizationMember: false,
        canDeleteUser: false,
        canEditOrganization: false,
        canEditUser: false,
        canViewPage: true,
      },
      vehicleAnalytics: {
        canUseFilter: false,
        canViewPage: false,
      },
      vehicleDetection: {
        canAddDataSet: false,
        canDeployModel: false,
        canExportDataSet: false,
        canImportModel: false,
        canUseFilter: false,
        canViewDataSet: false,
        canViewModelRepo: false,
        canViewPage: false,
      },
      vehicleHistory: {
        canDownloadEvent: false,
        canUpdateEventLog: false,
        canUseFilter: false,
        canViewPage: false,
      },
      vehicleSystemManagement: {
        canUseAnomaly: false,
        canUseFilter: false,
        canViewPage: false,
      },
      vehicleTask: {
        canDeleteTask: false,
        canPauseTask: false,
        canResumeTask: false,
        canUseFilter: false,
        canViewPage: false,
      },
      vlm: {
        canAdd: false,
        canEdit: false,
        canViewPage: false,
      },
    },
  },
];

export default PERMISSION_INIT_DATA;
