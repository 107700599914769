import { HomeSetting } from 'src/modules/user/domain/model/user';
import { useLayoutQuery } from 'src/modules/user/services/query/userQuery';

export type CreateStatus = {
  cameraTask: boolean;
};

export type DataEmptyStatus = {
  hasCamera: boolean;
  hasCameraEventLog: boolean;
  hasCameraTask: boolean;
  hasComputeTarget: boolean;
  hasDataSet: boolean;
  hasGroupTask: boolean;
  hasModel: boolean;
  hasTrafficAsset: boolean;
  hasVideo: boolean;
  hasVideoEventLog: boolean;
  hasVideoTask: boolean;
};

export type UseLayoutHook = {
  createStatus: CreateStatus;
  dataEmptyStatus: DataEmptyStatus;
  homeSetting: HomeSetting | null;
  isLoading: boolean;
};

function useLayout(): UseLayoutHook {
  const {
    data: {
      canCreate: { cameraTask },
      homeSetting,
      isCreated: {
        hasCamera,
        hasCameraEventLog,
        hasCameraTask,
        hasComputeTarget,
        hasDataSet,
        hasGroupTask,
        hasModel,
        hasTrafficAsset,
        hasVideo,
        hasVideoEventLog,
        hasVideoTask,
      },
    },
    isLoading,
  } = useLayoutQuery();

  return {
    createStatus: {
      cameraTask,
    },
    dataEmptyStatus: {
      hasCamera,
      hasCameraEventLog,
      hasCameraTask,
      hasComputeTarget,
      hasDataSet,
      hasGroupTask,
      hasModel,
      hasTrafficAsset,
      hasVideo,
      hasVideoEventLog,
      hasVideoTask,
    },
    homeSetting,
    isLoading,
  };
}

export default useLayout;
